<template>
  <div class="mb-5">
    <label class="mb-0">Group Name{{campaignGroupRequired ? '*' : ''}}</label>

    <div class="mad-lib-container">
      <basic-mad-lib-input :value="value"
                           fill-width
                           :allow-empty-strings="false"
                           :type-ahead-options="campaignGroupOptions"
                           @input="updateState($event)"></basic-mad-lib-input>
      <t-button-inline v-if="value && !campaignGroupRequired" severity="danger" class="ml-3" @click="handleClear(null)">
        <fluency-icon type="delete"></fluency-icon>
      </t-button-inline>
    </div>
    <small v-if="groupSelectionState === false" class="text-danger">Please select a group</small>
    <small v-else-if="campaignGroupOptions.length === 0" class="text-warning">No campaign group options available.</small>

    <t-alert v-if="warning" show class="mt-3" variant="warning">{{warning}}</t-alert>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'

export default {
  name: 'editCampaignGroup',
  components: { BasicMadLibInput },
  props: {
    value: {
      type: String
    },
    campaignPlan: Object,
    budgetPlan: Object,
    showNoOptions: { // showNoOptions = when you are inline editing still show an error if you can't edit it.
      type: Boolean,
      default: false
    },
    showInlineWarning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      campaignGroupOptions: [],
      noGroupOptions: false,
      warning: ''
    }
  },
  watch: {
    'campaignPlan.campaignPlanId': {
      handler (newVal, oldVal) {
        if (!newVal || newVal !== oldVal) {
          this.fetchCampaignGroupOptions()
        }
      },
      immediate: true
    },
    'budgetPlan.budgetPlanId': {
      handler (newVal, oldVal) {
        if (!newVal || newVal !== oldVal) {
          this.fetchCampaignGroupOptions()
        }
      },
      immediate: true
    }
  },
  computed: {
    groupSelectionState () {
      if (this.campaignGroupRequired) {
        if (this.value?.length > 1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    campaignGroupRequired () {
      return this.$store.getters.user.capabilities?.CampaignGroupRequired ?? false
    },
    planType () {
      if (this.campaignPlan) {
        return 'campaign'
      } else if (this.budgetPlan) {
        return 'budget'
      }
      return ''
    }
  },
  methods: {
    fetchCampaignGroupOptions: _debounce(async function () {
      const v = this
      v.$emit('fetchStatus', 'loading')
      const response = await this.$res.fetch.campaignGroupOptions(this.planType, this.campaignPlan || this.budgetPlan)
      if (response && response.length > 0) {
        v.$setCompat(v, 'campaignGroupOptions', response)
        v.$emit('fetchStatus', 'success')
      } else {
        v.$setCompat(v, 'noGroupOptions', true)
        v.$emit('fetchStatus', 'error')
      }
    }, 50),
    handleClear (value) {
      if (value === null) {
        this.$emit('input', null)
      }
    },
    async updateState (campaignGroupName) {
      let warning
      if (campaignGroupName && this.budgetPlan?.attachedCampaignPlanIds?.length > 0) {
        const data = await Promise.all(this.budgetPlan.attachedCampaignPlanIds.map(id => this.$res.fetch.campaignById(id)))
        const campaignsInDifferentGroup = data.map(d => d?.[0]).filter(c => c?.campaignGroupName && c.campaignGroupName !== campaignGroupName)
        if (campaignsInDifferentGroup.length > 0) {
          warning = `Changing this campaign group might have unintended side effects. This budget has the following campaigns attached, which are in a different campaign group:\r\n\r\n${campaignsInDifferentGroup.map(c => `${c.name} - ${c.campaignGroupName}`).join('\r\n')}`
        }
      } else if (this?.campaignPlan?.campaignGroupId &&
        this?.campaignPlan?.budgetPlan?.campaignGroupId &&
        this.campaignPlan.campaignGroupId === this.campaignPlan.budgetPlan.campaignGroupId &&
        this.campaignPlan.campaignGroupName !== campaignGroupName) {
        warning = `Changing this campaign group might have unintended side effects. This campaign is attached to a budget in the ${this.campaignPlan.campaignGroupName} campaign group.`
      }
      if (warning) {
        if (this.showInlineWarning) {
          this.warning = warning
        } else {
          await this.$alert(warning)
        }
      } else {
        this.warning = ''
      }
      this.$emit('input', campaignGroupName)
    }
  }
}
</script>

<style lang="scss">
.is-invalid .vs__dropdown-toggle {
  border-color: $fluency-red;
}
</style>
